import axios from 'axios';
import { LOGIN_BASE_PATH } from './api-base-path';




export const UserLogin = (data) => { 
    console.log(data)
    return axios.post(`${LOGIN_BASE_PATH}/userlogin`, data);
  };

  export const getAllCustomer = () => {

    return axios.get(`${LOGIN_BASE_PATH}/customers`,);
  };


  export const addSchCustomer = (data) => { 
    console.log(data)
    return axios.post(`${LOGIN_BASE_PATH}/addSchCustomer`, data);
  };

// src/index.js (or main.js)
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';  // Wrap your app with BrowserRouter
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <BrowserRouter>  {/* This is where you wrap your entire app */}
    <App />
  </BrowserRouter>
);

import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Button, Grid, Snackbar, Alert, Box, CircularProgress } from '@mui/material';
import { addSchCustomer, getAllCustomer } from '../lib/api-login';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Dashboard = () => {
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
  const [loading, setLoading] = useState(false); // Track loading state for adding customer
  const [fetching, setFetching] = useState(false); // Track loading state for fetching customers

  useEffect(() => {
    const fetchCustomers = async () => {
      setFetching(true); // Start loading
      try {
        const response = await getAllCustomer();
        console.log(response);
        const { customers } = response.data;
        const customerOptions = customers.map(customer => ({
          label: customer.customer_name,
          id: customer.customer_id
        }));
        setCustomers(customerOptions);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setFetching(false); // End loading
      }
    };

    fetchCustomers();
  }, []);

  const addHandler = async () => {
    if (!selectedOption) {
      setSnackbarMessage('Please select a customer');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (inputValue) {
      const data = {
        customer_id: selectedOption.id,
        sch_code: inputValue
      };

      setLoading(true); // Start loading while adding customer
      try {
        const response = await addSchCustomer(data);
        console.log('Response from addSchCustomer:', response);
        setSnackbarMessage('Customer added successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setInputValue('');  
        setSelectedOption(null);  
      } catch (error) {
        console.error('Error adding customer:', error);

        if (error.response) {
          if (error.response.status === 400) {
            const errorMessage = error.response.data.message || 'Error adding customer';
            setSnackbarMessage(errorMessage);
          } else {
            setSnackbarMessage('An unexpected error occurred');
          }
        } else {
          setSnackbarMessage('Network error. Please try again');
        }
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false); // End loading
      }
    } else {
      setSnackbarMessage('Please enter a store code');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleLogout = () => {
    localStorage.clear();  
    window.location.href = '/'; 
  };

  const isLoading = loading || fetching; // Combine both loading states

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh', padding: 2, position: 'relative' }}>
      
      {/* Full-Screen Loader */}
      {isLoading && (
        <Box 
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          height: '50vh',
          padding: 2,
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            value={selectedOption}
            onChange={(event, newValue) => setSelectedOption(newValue)}
            options={customers}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => <TextField {...params} label="Select Customer" variant="outlined" />}
            fullWidth
            size='small'
            disabled={fetching} // Disable Autocomplete while fetching customers
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Store Code"
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            fullWidth
            size='small'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={addHandler}
            fullWidth
            disabled={loading} // Disable the button while adding customer
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Add'}
          </Button>
        </Grid>

        {/* Logout Button in the top-right corner */}
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Button variant="contained" color="secondary" onClick={handleLogout} startIcon={<ExitToAppIcon />} >
            Logout
          </Button>
        </Box>

        {/* Snackbar for displaying success or error message */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Box>
  );
};

export default Dashboard;

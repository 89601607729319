// src/pages/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('login_success');


  if (!isAuthenticated || isAuthenticated !== 'true') {
    return <Navigate to="/" />;
  }

  return children;  
};

export default ProtectedRoute;

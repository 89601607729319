// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';  // Only use Routes and Route here
import ProtectedRoute from './page/ProtectedRoute';
import Login from './page/Login';
import Dashboard from './page/Dashboard';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route 
        path="/dashboard" 
        element={
          <ProtectedRoute>
            <Dashboard /> 
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
}

export default App;

import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Stack, Button, TextField } from '@mui/material';
import web from '../images/web.png';
import tnb_logo_01 from '../images/tnb_logo_01.png';
import logo from "../images/logo.png";
import LoadingSpinner from '../ui/LoadingSpinner';
import { UserLogin } from '../lib/api-login';


const initialState = {
  username: '',
  password: ''
};

const initialErrorState = {
  username: {
    invalid: false,
    message: ''
  },
  password: {
    invalid: false,
    message: ''
  }
};

const Login = () => {
  const [loginData, setLoginData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialErrorState);
  const [httpError, setHttpError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();

  const validateForm = (formData) => {
    const errors = { ...initialErrorState };
    if (!formData.username || formData.username.trim() === '') {
      errors.username = { invalid: true, message: 'Username is required' };
    }
    if (!formData.password || formData.password.trim() === '') {
      errors.password = { invalid: true, message: 'Password is required' };
    } else if (formData.password.trim().length < 5) {
      errors.password = { invalid: true, message: 'Password length should be 5 or more characters' };
    }
    return errors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const errors = validateForm(loginData);
    setFormErrors(errors);
    if (errors.username.invalid || errors.password.invalid) {
      return;
    }
  
    try {
      setIsLoading(true); 
      const response = await UserLogin(loginData);
  
      if (response.status === 200) {

        console.log(response.data.message);  
        localStorage.setItem('login_success', 'true'); 
        localStorage.setItem('user_data', JSON.stringify(response.data.user));  
        navigate('/dashboard');
      }
    } catch (error) {
      setIsLoading(false); 
      if (error.response && error.response.data) {
        setHttpError(error.response.data.error); 
      } else {
        setHttpError('An error occurred. Please try again.');
      }
    }
  };
  
  
  
  

  const inputChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const toggle = () => {
    if (ref.current.type === "text") {
      ref.current.type = "password";
    } else {
      ref.current.type = "text";
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#f4f7fc',
          padding: '20px',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '40px',
            borderRadius: '8px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '400px',
            textAlign: 'center',
          }}
        >
          <img
            src={logo}
            alt="Transporter NoteBook"
            width="60%"
            style={{ marginBottom: '30px' }}
          />
          <form onSubmit={submitHandler}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="username"
              label="Username or Email ID"
              value={loginData.username}
              onChange={inputChangeHandler}
              error={formErrors.username.invalid}
              helperText={formErrors.username.message}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="password"
              label="Password"
              type="password"
              value={loginData.password}
              onChange={inputChangeHandler}
              error={formErrors.password.invalid}
              helperText={formErrors.password.message}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '20px' }}
            >
              LOGIN
            </Button>
          </form>

          {httpError && (
            <Stack sx={{ width: '100%', marginTop: '20px' }}>
              <Alert severity="error">{httpError}</Alert>
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
